'use client';

import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useSession } from 'next-auth/react';
import { usePathname } from 'next/navigation';
import Script from 'next/script';

import type { User } from '@/types';
import { GTM_ID } from '@/utils/gtm';

export const GTMHeadScript = () => {
  const { data: session } = useSession();
  const pathname = usePathname();

  const user = session?.user as User;

  useEffect(() => {
    if (window && user?.id) {
      if (window?.dataLayer) {
        window.dataLayer.push({ userId: user.id });
      }

      if (
        window?.ps &&
        window?.ps?.addMeta &&
        typeof window?.ps?.addMeta === 'function'
      ) {
        window.ps.addMeta({ email: user.email });
      }

      window.lc = window.lc || ({} as typeof window.lc);
      window.lc.identity = {
        name: user.name,
        email: user.email,
        distinctId: user.id,
      };

      Sentry.setUser({ id: user.id, email: user.email });
      Sentry.setTags({
        workspaceId: user?.workspace.id,
        plan: user?.workspace.plan.id,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  if (pathname?.includes('aibot-iframe')) return null;
  if (pathname?.includes('generator-ai')) return null;
  if (pathname?.includes('free-tools')) return null;
  if (pathname?.includes('privacy-policy-summarizer')) return null;
  if (pathname?.includes('api-pricing-calculator')) return null;
  if (pathname?.includes('create-whatsapp-links')) return null;
  if (pathname?.includes('page-speed-insights')) return null;

  return (
    <>
      <Script
        id="gtm-data-layer-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`,
        }}
      />
    </>
  );
};
