type PlanName =
  | 'Free'
  | 'Basic'
  | 'Pro'
  | 'Expert'
  | 'Enterprise'
  | 'Advanced'
  | 'Appsumo Tier 1'
  | 'Appsumo Tier 2'
  | 'Appsumo Tier 3';

export type PlanIds =
  | 1
  | 21
  | 22
  | 31
  | 32
  | 41
  | 42
  | 51
  | 61
  | 62
  | 91
  | 92
  | 93;

export const planIdToName: {
  [key in PlanIds]: PlanName;
} = {
  1: 'Free',
  21: 'Basic',
  22: 'Basic',
  31: 'Pro',
  32: 'Pro',
  41: 'Expert',
  42: 'Expert',
  51: 'Enterprise',
  61: 'Advanced',
  62: 'Advanced',
  91: 'Appsumo Tier 1',
  92: 'Appsumo Tier 2',
  93: 'Appsumo Tier 3',
};

export const planIdToNameColor: {
  [key: number]: {
    name: string;
    color: string;
    textColor: string;
    style?: string;
  };
} = {
  1: {
    name: 'Free',
    color: 'transparent',
    textColor: 'gray-500',
    style: 'border border-gray-200',
  },
  21: { name: 'Basic', color: 'gray-200', textColor: 'gray-800' },
  22: { name: 'Basic', color: 'gray-200', textColor: 'gray-800' },
  31: { name: 'Pro', color: 'blue-100', textColor: 'blue-600' },
  32: { name: 'Pro', color: 'blue-100', textColor: 'blue-600' },
  41: { name: 'Expert', color: 'green-100', textColor: 'green-800' },
  42: { name: 'Expert', color: 'green-100', textColor: 'green-800' },
  51: { name: 'Enterprise', color: 'red-100', textColor: 'red-800' },
  61: { name: 'Advanced', color: 'purple-100', textColor: 'purple-800' },
  62: { name: 'Advanced', color: 'purple-100', textColor: 'purple-800' },
  91: { name: 'Lifetime', color: 'gray-200', textColor: 'gray-800' },
  92: { name: 'Lifetime', color: 'blue-100', textColor: 'blue-600' },
  93: { name: 'Lifetime', color: 'green-100', textColor: 'green-800' },
};

export const FREE_PLAN_ID: PlanIds = 1;
export const BASIC_PLAN_IDS: PlanIds[] = [21, 22];
export const EXPERT_PLAN_IDS: PlanIds[] = [41, 42];
export const APPSUMO_PLAN_IDS: PlanIds[] = [91, 92, 93];
