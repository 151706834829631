'use client';
import type { User } from '@/types';

import { PostHogProvider as Provider } from 'posthog-js/react';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { useEffect, useMemo } from 'react';
import { safePaths } from '@/config/path';
import posthog from 'posthog-js';

export function PostHogProvider({ children }) {
  const { data: session } = useSession();
  const pathname = usePathname();

  const user = session?.user as User;

  const isDisabledPage = useMemo(() => {
    const isSafe = safePaths.some((prefix) => pathname.includes(prefix));

    return isSafe;
  }, [pathname]);

  const isTestUser = useMemo(() => {
    return user?.email.includes('popupsmart');
  }, [user]);

  useEffect(() => {
    if (isDisabledPage) return;
    if (isTestUser) return;

    if (window) {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
        loaded: (posthog) => {
          if (process.env.NODE_ENV === 'development') posthog.debug(); // debug mode in development
        },
      });

      if (user?.id) {
        posthog.identify(user.id, {
          email: user.email,
          name: user.name,
          workspaceId: user?.workspace.id,
          plan: user?.workspace.plan.id,
        });
      } else {
        posthog.reset();
      }
    }
  }, [isDisabledPage, isTestUser, user]);

  if (isDisabledPage || isTestUser) return <>{children}</>;

  return <Provider client={posthog}>{children}</Provider>;
}
