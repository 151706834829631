export const safePaths = [
  'static',
  '_next',
  'api',
  'register',
  'login',
  'retool-login',
  'forgot-password',
  'reset-password',
  'accept-invite',
  'aibot-iframe',
  'embed.js',
  'assets',
  'privacy-policy-summarizer',
  'free-tools',
  'bar-chart-generator-ai',
  'bar-graph-maker',
  'pie-chart-generator-ai',
  'line-chart-generator-ai',
  'table-generator-ai',
  'column-chart-generator-ai',
  'tweet-generator-ai',
  'ai-bot',
  'meta-information-generator-ai',
  'instagram-post-generator-ai',
  'youtube-post-generator-ai',
  'create-whatsapp-links',
  'page-speed-insights',
  'preview',
  'image-alt-text-generator-ai',
  'ai-blog-outline-creator',
  'ai-blog-post-ideas-generator',
  'ai-business-name-generator',
  'ai-competitor-analysis-tool',
  'ai-document-summarizer',
  'ai-email-generator-tool',
  'ai-product-description-generator',
  'ai-resume-analyzer',
  'ai-sentence-rewriter-tool',
  'ai-slogan-generator',
  'ai-subject-line-generator',
  'chatbot-name-generator',
];

export const freeTools = [
  '/api-pricing-calculator',
  '/bar-chart-generator-ai',
  '/bar-graph-maker',
  '/column-chart-generator-ai',
  '/create-whatsapp-links',
  '/image-alt-text-generator-ai',
  '/instagram-post-generator-ai',
  '/line-chart-generator-ai',
  '/meta-information-generator-ai',
  '/meta-information-generator-ai-popupsmart',
  '/page-speed-insights',
  '/pie-chart-generator-ai',
  '/privacy-policy-summarizer',
  '/table-generator-ai',
  '/tweet-generator-ai',
  '/chatbot-name-generator',
  '/youtube-post-generator-ai',
];
